<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="经销商">
					{{ detail.seller_name||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="司机">
					{{ detail.chauffeur||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="司机电话">
					{{ detail.chauffeur_mobile||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="车牌">
					{{ detail.truck_license||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="状态">
					{{ detail.status_name }}
				</a-descriptions-item>
				<a-descriptions-item label="寄存时间">
					{{ detail.create_time }}
				</a-descriptions-item>
				<a-descriptions-item label="是否寄存">
                    <div v-if="detail.deposit_ids" @click="go_deposit">是</div>
                    <div v-else>否</div>
					<!-- {{ detail.deposit_ids?'是':'否' }} -->
				</a-descriptions-item>
			</a-descriptions>

			<a-divider orientation="left">托盘信息</a-divider>	
            <div class="top_box">
                <div>托盘总数：{{ detail.num.total_num }}</div>
                <div>完好数量：{{ detail.num.good_num }}</div>
                <div>损坏数量：{{ detail.num.bad_num }}</div>
            </div>
            <div class="table_box">
                <div class="tr_box">
                    <div v-for="(value,index) in detail.goods_data.title" :key="index">{{ value.label }}</div>
                </div>
                <div>
                    <div v-for="(value,index) in detail.goods_data.goods_data" :key="index" class="td_box">
                        <div v-for="(va,ind) in detail.goods_data.title">{{ value[va.value].data }}</div>
                    </div>
                </div>
            </div>

            <a-divider orientation="left" v-if="detail.num.bad_num > 0">损坏托盘生产日期</a-divider>
            <div class="product_box" v-if="detail.num.bad_num > 0">
                <div v-for="(value,index) in detail.product_data" :key="index" class="product_info">
                    <div class="product_top">
                        <!-- <div>{{ value.goods_name }}</div>
                        <div>{{ value.total_num }}</div> -->
                        {{ value.goods_name }}损坏数量：{{ value.total_num }}
                    </div>
                    <div class="product_box">
                        <div class="product_tr">
                            <div>数量（块）</div>
                            <div>托盘生产日期</div>
                        </div>
                        <div v-for="(va,ind) in value.data" :key="ind" class="product_td">
                            <div>{{ va.num }}</div>
                            <div>{{ va.product_date }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <a-divider orientation="left" v-if="detail.num.bad_num > 0">损坏图片</a-divider>
            <div v-if="detail.num.bad_num > 0">
                <div  v-for="(value,index) in detail.image_data" :key="index">
                    <div>{{ value.goods_name }}</div>
                    <div v-for="(va,ind) in value.bad" :key="ind" class="image_info">
                        <div class="back_img" :style="{background:'url('+va.image+')', backgroundSize:'100%', backgroundRepeat: 'no-repeat'}"></div>
                        <!-- <image :src="va.image"  class="image_box"/> -->
                        <!-- <a-image width="100" :src="va.image" /> -->
                    </div>
                </div>
            </div>
            
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		get_tray_detail,
	} from '@/api/push.js'
	import LookImages from "@/components/LookImages";

	export default {
		components: {
            LookImages
		},
		data() {
			return {
				detail: {
                    num:{
                        total_num:0,
                        good_num:0,
                        bad_num:0
                    }
                }
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_info() {
				get_tray_detail({
					data: {
						tray_id: this.$route.query.id,
					}
				}).then(res => {
					this.detail = res.data.list
				})
			},
            go_deposit(){
                this.$router.push({
                    path: '/deposit/index',
                    query: {
                        deposit_ids: this.detail.deposit_ids
                    }
                })
            }
		}
	}
</script>

<style lang="less" scoped>

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.ware_box {
		display: flex;
		align-items: center;

		.btn {
			margin-left: 20px;
		}
	}

    // .btn_box {
    //     margin-bottom: 20px;
    // }

    .top_box {
        display: flex;
        width: 750px;
        height: 33px;
        align-items: center;
        background-color: #FAFAFA;
        margin-bottom: 10px;
        div {
            width: 30%;
            color: #1890FF;
        }
    }

    .table_box {
        width: 750px;
        .tr_box {
            display: flex;
            margin-bottom: 15px;
        }
        .tr_box div {
            width: 25%;
        }
        .td_box{
            display: flex;
        }
        .td_box div {
            width: 25%;
            height: 30px;
        }
    }

    .product_box {
        width: 500px;
        .product_top {
            margin-bottom: 10px;
            background-color: #FAFAFA;
            line-height: 30px;
            color: #1890FF;
        }

        .product_info{
            margin-bottom: 20px;
        }

        .product_tr {
            display: flex;
            margin-bottom: 15px;
            div{
                width: 50%;
            }
        }

        .product_td {
            display: flex;
            div{
                width: 50%;
                height: 30px;
            }
        }
    }

    // .image_info {
    //     display: flex;
    // }

    .image_info {
        width: 100px;
        margin-right: 8px;
        margin-top: 10px;
        height: 100px;
        display: inline-block;
        .back_img {
            width: 100%;
            height: 100%;
        }
        img{
            height: 100%;
        }
    }
    
</style>
